import React from 'react';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";
// Import Project Mockups
import MockupOne from "../../images/ProjectMockupOne.jpeg";
import MockupTwo from "../../images/ProjectMockupTwo.jpeg";
import MockupThree from "../../images/ProjectMockupThree.jpeg";
import MockupFour from "../../images/ProjectMockupFour.jpeg";
import MockupFive from "../../images/ProjectMockupFive.jpeg";
import MockupSix from "../../images/ProjectMockupSix.jpeg";
import MockupSeven from "../../images/ProjectMockupSeven.jpeg";
import MockupEight from "../../images/ProjectMockupEight.jpeg";
import MockupNine from "../../images/ProjectMockupNine.jpeg";
import './Projects.css';

export default function Projects() {
  return (
    <div className='Projects'>
      <h1>Our Projects</h1>
      <div className='projects-content'>
        <div className='project-container projects-container-one'>
          <img src={MockupOne} alt='A Mockup of one of our Projects' className='project-mockup' />
          <img src={MockupFour} alt='A Mockup of one of our Projects' className='project-mockup' />
          <img src={MockupSeven} alt='A Mockup of one of our Projects' className='project-mockup' />
          <span className='projectDisplayMedium'>
            <img src={MockupThree} alt='A Mockup of one of our Projects' className='project-mockup' />
            <img src={MockupSix} alt='A Mockup of one of our Projects' className='project-mockup' />
          </span>
          <span className='projectDisplaySmall'>
            <img src={MockupTwo} alt='A Mockup of one of our Projects' className='project-mockup' />
            <img src={MockupFive} alt='A Mockup of one of our Projects' className='project-mockup' />
            <img src={MockupEight} alt='A Mockup of one of our Projects' className='project-mockup' />
            <img src={MockupThree} alt='A Mockup of one of our Projects' className='project-mockup' />
            <img src={MockupSix} alt='A Mockup of one of our Projects' className='project-mockup' />
            <img src={MockupNine} alt='A Mockup of one of our Projects' className='project-mockup' />
          </span>
        </div>
        <p className='scroll-text'>
          <IoIosArrowRoundBack className='scroll-text-icon' /> Scroll <IoIosArrowRoundForward className='scroll-text-icon' />
        </p>
        <div className='project-container projects-container-two'>
          <img src={MockupTwo} alt='A Mockup of one of our Projects' className='project-mockup' />
          <img src={MockupFive} alt='A Mockup of one of our Projects' className='project-mockup' />
          <img src={MockupEight} alt='A Mockup of one of our Projects' className='project-mockup' />
          <span className='projectDisplayMedium'>
            <img src={MockupNine} alt='A Mockup of one of our Projects' className='project-mockup' />
          </span>
        </div>
        <p className='scroll-text scroll-text-two'>
          <IoIosArrowRoundBack /> Scroll <IoIosArrowRoundForward />
        </p>
        <div className='project-container projects-container-three'>
          <img src={MockupThree} alt='A Mockup of one of our Projects' className='project-mockup' />
          <img src={MockupSix} alt='A Mockup of one of our Projects' className='project-mockup' />
          <img src={MockupNine} alt='A Mockup of one of our Projects' className='project-mockup' />
        </div>
      </div>
    </div>
  );
}