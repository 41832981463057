import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import Logo from './images/wedifyLogo.jpeg';
import menuTogglerLine from './images/menuTogglerLine.png';
import Home from './components/Home/Home';
import About from './components/About/About';
import Features from './components/Features/Features';
import Pricing from './components/Pricing/Pricing';
import Projects from './components/Projects/Projects';
import Contact from './components/Contact/Contact';
import './App.css';

function App() {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleTogglerClick = () => {
    setIsMenuOpen(prevState => !prevState);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="App">

      <header className="App-header" id='header'>
        <Link to="/">
          <img src={Logo} alt="Logo" className="App-logo" />
        </Link>
        <nav>
          <button className={`navbar-toggler ${isMenuOpen ? 'navbar-menuOpen' : ''}`} onClick={handleTogglerClick}>
            <img src={menuTogglerLine} alt='' className='toggler-line toggler-line-one' />
            <img src={menuTogglerLine} alt='' className='toggler-line toggler-line-two' />
            <img src={menuTogglerLine} alt='' className='toggler-line toggler-line-three' />
          </button>
          {isMenuOpen && (
            <ul className={`navigation-collapsed ${isMenuOpen ? 'navbar-menuOpen' : ''}`} >
              <li className='navigation-collapsed-link'>
                <Link to="/" onClick={handleTogglerClick}>Home</Link>
              </li>
              <li className='navigation-collapsed-link'>
                <Link to="/about" onClick={handleTogglerClick}>About</Link>
              </li>
              <li className='navigation-collapsed-link'>
                <Link to="/features" onClick={handleTogglerClick}>Features</Link>
              </li>
              <li className='navigation-collapsed-link'>
                <Link to="/pricing" onClick={handleTogglerClick}>Pricing</Link>
              </li>
              <li className='navigation-collapsed-link'>
                <Link to="/projects" onClick={handleTogglerClick}>Projects</Link>
              </li>
              <li className='navigation-collapsed-link'>
                <Link to="/contact" onClick={handleTogglerClick}>Contact</Link>
              </li>
            </ul>
          )}
          <ul className='navigation-links' >
            <li className='navigation-link'>
              <Link to="/">Home</Link>
            </li>
            <li className='navigation-link'>
              <Link to="/about">About</Link>
            </li>
            <li className='navigation-link'>
              <Link to="/features">Features</Link>
            </li>
            <li className='navigation-link'>
              <Link to="/pricing">Pricing</Link>
            </li>
            <li className='navigation-link'>
              <Link to="/projects">Projects</Link>
            </li>
            <li className='navigation-link'>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </nav>
      </header>
      <main>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/features' element={<Features />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
      </main>
      {location.pathname !== '/pricing' && (
        <footer className='App-footer'>
          <img src={Logo} alt='Logo' className='footer-logo' />
          <p className='footer-copyright'>Wedify &copy; 2024</p>
        </footer>
      )}
    </div>
  );
}

export default App;
