import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';

export default function Contact() {

  // EmailJS variables
  const serviceID = "service_bx1bd8a";
  const templateID = "template_3e91tup";

  // State Variable for Form Submission
  const [formSubmitted, setForSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setForSubmitted(true);

    emailjs.sendForm(serviceID, templateID, '#myForm', process.env.REACT_APP_EMAILJS_USER_ID)
      .then((response) => {
        console.log(response.status, response.text);
      })
      .catch((error) => {
        alert('There was an error receiving your form submission. Please contact us via email.');
      });
  };

  return (
    <div className='Contact'>
      <div>
        <h1 className='UpwardSpanOne'>Get in touch!</h1>
        <div className='UpwardSpanTwo'>
          <p id='contactText'>We're excited to help you create the perfect wedding website. Reach out to us using the form, or contact us directly via email and we'll get back to you as soon as possible.</p>
          <p className='contactSubHeading'>
            <strong>Email</strong>
          </p>
          <a href='mailto:info@wedify.co.za'>info@wedify.co.za</a>
          <p className='contactSubHeading'>
            <strong>Operating Hours</strong>
          </p>
          <p>Monday - Friday: 9AM - 5PM</p>
        </div>
      </div>
      <div>
        <div className='contact-form'>
          <h1>Contact Form</h1>
          {!formSubmitted &&
            <form id="myForm" onSubmit={handleSubmit}>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                id="name"
              />

              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
              />

              <label htmlFor="number">Number</label>
              <input
                type="tel"
                name="number"
                id="number"
              />

              <label htmlFor="message">Message</label>
              <textarea
                name="message"
                id="message"
                rows={10}
                cols={30}
              ></textarea>

              <button type="submit" id='formSubmitButton'>Submit</button>
            </form>
          }
          {formSubmitted &&
            <h6 id='formSubmissionMessage'>Your response has been received! We will get back to you as soon as possible.</h6>
          }
        </div>
      </div>
    </div>
  );
}
