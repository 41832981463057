import './Features.css';
import eventDetails from '../../images/eventDetails.png';
import rsvpManagement from '../../images/rsvpManagement.png';
import countdownTimer from '../../images/countdownTimer.png';
import languageSelector from '../../images/languageSelector.png';
import extraFeatures from '../../images/extraFeatures.png';

export default function Features() {
  return (
    <div className='Features'>
      <div id='features-hero'>
        <h1>Features</h1>
        <p className='page-description'>At Wedify, we believe your website should be more than just an ordinary wedding invite. Our comprehensive list of features is designed to make it seamless, interactive, and memorable. From managing guest lists to sharing precious moments, explore how we transform every detail into a beautiful, personalised experience.</p>
      </div>
      <div className='featurePage-container'>
        <div className='featurePage-featureText'>
          <h5>Share all the</h5>
          <h2>Event Details</h2>
          <p>Keep your guests informed with a detailed schedule of events. From the ceremony to the reception, ensure everyone knows what, when, and where.</p>
        </div>
        <img src={eventDetails} alt='Mockup with event details of wedding website' className='featuresPage-featureImage' />
      </div>
      <div className='featurePage-container oddFeature'>
        <img src={rsvpManagement} alt='Mockups with RSVP forms' className='featuresPage-featureImage' />
        <div className='featurePage-featureText'>
          <h5>Simplify your guest list Using</h5>
          <h2>RSVP Management</h2>
          <p>Built using Google Forms, our RSVP system effortlessly handles your guest list for you and keeps you informed via email. Track responses, manage meal preferences, and collect essential info from your guests.</p>
        </div>
      </div>
      <div className='featurePage-container'>
        <div className='featurePage-featureText'>
          <h5>Keep track with a</h5>
          <h2>Countdown Timer</h2>
          <p>Build excitement for your big day with a real-time countdown timer. Keep your guests engaged and looking forward to the celebration.</p>
        </div>
        <img src={countdownTimer} alt='Mockup with a countdown timer showing the days, hours, minutes, and seconds left till the big day!' className='featuresPage-featureImage' />
      </div>
      <div className='featurePage-container oddFeature'>
        <img src={languageSelector} alt='Mockup with the leanguage selector, showing how easy it is to switch to Afrikaans' className='featuresPage-featureImage' />
        <div className='featurePage-featureText'>
          <h5>Accommodate Guests with the</h5>
          <h2>Language Selector</h2>
          <p>With our language selector, you can make your website accessible and welcoming to all guests. This allows you to provide content in the languages of your choice, ensuring everyone feels included and informed. </p>
        </div>
      </div>
      <div className='extraFeatures'>
        <div>
          <h2>And Many More...</h2>
          <p>Enjoy these features and more with Wedify, and turn your wedding invite into an essential tool for planning and sharing your special day. Whether you're tech-savvy or prefer a simple approach, we offer the flexibility and functionality to meet your needs. Let us make your wedding journey as extraordinary as your love story!</p>
        </div>
        <img src={extraFeatures} alt='Some of the many other features we offer' className='extraFeatures-image' />
      </div>
    </div>
  );
}