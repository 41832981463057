import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import heroSectionMockups from '../../images/heroSectionMockups.jpeg';
import heroSectionMockupsMedium from '../../images/heroSectionMockupsMedium.jpeg';
import heroSectionMockupsSmall from '../../images/heroSectionMockupsSmall.jpeg';
import customiseIcon from '../../images/customiseIcon.png';
import rsvpIcon from '../../images/rsvpIcon.png';
import domainIcon from '../../images/domainIcon.png';
import updateIcon from '../../images/updateIcon.png';
import mockupOne from '../../images/mockupOne.jpeg';
import mockupTwo from '../../images/mockupTwo.jpeg';
import mockupThree from '../../images/mockupThree.jpeg';
import designImage from '../../images/designImage.jpeg';
import developmentImage from '../../images/developmentImage.jpeg';
import inviteImage from '../../images/inviteImage.jpeg';
import testimonialPhotoOne from "../../images/testimonialPhotoOne.jpeg";
import testimonialPhotoTwo from "../../images/testimonialPhotoTwo.jpeg";
import testimonialPhotoThree from "../../images/testimonialPhotoThree.jpeg";
import { FaInstagram, FaTiktok, FaFacebook, FaWhatsapp } from "react-icons/fa";
import SAweddingsBadge from "../../images/badge.png";
import './Home.css';

export default function Home() {

  // State variables for timed mockup display
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);
  const images = [mockupOne, mockupTwo, mockupThree];

  // State variable for tabs
  const [activeTab, setActiveTab] = useState("tabOne");

  // Function for mockup image timing
  useEffect(() => {
    const interval = setInterval(() => {
      setFadeIn(false); // Trigger fade-out by removing class
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFadeIn(true);
      }, 350);
    }, 4000);

    return () => clearInterval(interval);
  }, [images.length]);

  const openTab = (tabName) => {
    setActiveTab(tabName);
  }

  return (
    <div className='Home'>
      <section id='landing'>
        <div id='landing-content'>
          <h1>
            Your dream wedding
            <br />
            needs a dream website.
          </h1>
          <p>
            Say 'I Do' to effortless wedding planning
            <br />
            with a personalised wedding website and
            <br />
            an easy-to-use digital RSVP system.
          </p>
          <Link to="/projects" className='link-button'>Explore Designs</Link>
        </div>
        <p className='heroDisclaimer'>*Designs have been altered for privacy purposes.</p>
        <picture>
          <source srcSet={heroSectionMockupsMedium} media="(min-width: 992px) and (max-width: 1200px)" id='heroSectionMockupsMedium' />
          <source srcSet={heroSectionMockupsSmall} media='(max-width: 576px)' id='heroSectionMockupMedium' />
          <img src={heroSectionMockups} alt="Some of Our Latest Design Mockups" id="heroSectionMockups" />
          <a href="https://wed-ify.co.za/demo.html" target="_blank" rel="noopener noreferrer" className='liveDemoButton'>Press for Live Demo</a>
        </picture>

      </section>
      <section id='features'>
        <h1>Why Wedify?</h1>
        <div className='feature-container'>
          <div className='feature'>
            <div className='feature-icon-container'>
              <img src={customiseIcon} alt='Customise Icon' className='feature-icon' />
            </div>
            <p>
              <strong>Fully Customisable</strong>
            </p>
            <p className='feature-description'>Whether it's layout, colour scheme, fonts, or features, our customised service reflects your unique style and personality</p>
          </div>
          <div className='feature'>
            <div className='feature-icon-container'>
              <img src={rsvpIcon} alt='RSVP Icon' className='feature-icon' />
            </div>
            <p>
              <strong>RSVP Management</strong>
            </p>
            <p className='feature-description'>Our RSVP system uses Google Forms, allowing you to keep track of your guest list and RSVPs without lifting a finger. You'll even be notified with each response!</p>
          </div>
          <div className='feature'>
            <div className='feature-icon-container'>
              <img src={domainIcon} alt='Custom Domain Icon' className='feature-icon' />
            </div>
            <p>
              <strong>Custom Domain Name</strong>
            </p>
            <p className='feature-description'>Personalize your wedding website with a custom domain to truly make it your own, adding a unique and memorable touch to your digital invite.</p>
          </div>
          <div className='feature'>
            <div className='feature-icon-container'>
              <img src={updateIcon} alt='Updates Icon' className='feature-icon' />
            </div>
            <p>
              <strong>Post-Wedding Updates</strong>
            </p>
            <p className='feature-description'>Leave your guests a 'thank you for attending' message or share photos from the wedding through various cloud platforms—all in one place.</p>
          </div>
        </div>
      </section>
      <section id='mockups'>
        <div className='mockups-container'>
          <img src={mockupOne} alt='Design Mockup' className='mockupImage mockupOne' />
          <img src={mockupTwo} alt='Design Mockup' className='mockupImage mockupTwo' />
          <img src={mockupThree} alt='Design Mockup' className='mockupImage mockupThree' />
        </div>
        <div className='timer-container'>
          <img
            src={images[currentImageIndex]}
            alt='Design Mockup'
            className={`mockupImage ${fadeIn ? 'fade-in' : ''}`}
          />
        </div>
        <p className='mockupDisclaimer'>*Designs have been altered for privacy purposes.</p>
      </section>
      <section id='howItWorks'>
        <h1>How it works?</h1>
        <div className='tab-container'>
          <div className='tab-button-container'>
            <button onClick={() => openTab('tabOne')} className={`tab-button ${activeTab === 'tabOne' ? 'activeButton' : ''}`}>Step One</button>
            <button onClick={() => openTab('tabTwo')} className={`tab-button ${activeTab === 'tabTwo' ? 'activeButton' : ''}`}>Step Two</button>
            <button onClick={() => openTab('tabThree')} className={`tab-button ${activeTab === 'tabThree' ? 'activeButton' : ''}`}>Step Three</button>
          </div>
          <div className={`tab ${activeTab === 'tabOne' ? 'active' : ''}`}>
            <div className='step step-one'>
              <img src={designImage} alt='Design Stage' className='step-image' />
              <div className='step-description'>
                <p>
                  <strong>Consultation</strong>
                </p>
                <p>After we receive your payment, we'll reach out to schedule a detailed consultation so we can understand your vision, style, and requirements for your wedding website.</p>
              </div>
            </div>
          </div>
          <div className={`tab ${activeTab === 'tabTwo' ? 'active' : ''}`}>
            <div className='step step-two'>
              <img src={developmentImage} alt='Development Stage' className='step-image' />
              <div className='step-description'>
                <p>
                  <strong>Development</strong>
                </p>
                <p>Once you provide us with the content you'd like to include on your website, such as your love story, event details, and RSVP information, we'll build your website from the ground up within 1-2 weeks.</p>
              </div>
            </div>
          </div>
          <div className={`tab ${activeTab === 'tabThree' ? 'active' : ''}`}>
            <div className='step step-three'>
              <img src={inviteImage} alt='Invitation Mockup' className='step-image' />
              <div className='step-description'>
                <p>
                  <strong>Send Invites</strong>
                </p>
                <p>Once your website is ready, you'll have the opportunity to review it and request any final adjustments. When you're satisfied, we'll launch your stunning wedding website for all your guests to see.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='steps-container'>
          <div className='step step-one'>
            <img src={designImage} alt='Design Stage' className='step-image' />
            <div className='step-description'>
              <p>
                <strong>Consultation</strong>
              </p>
              <p>After we receive your payment, we'll reach out to schedule a detailed consultation so we can understand your vision, style, and requirements for your wedding website.</p>
            </div>
            <p className='step-label'>STEP ONE</p>
          </div>
          <div className='step step-two'>
            <img src={developmentImage} alt='Development Stage' className='step-image' />
            <div className='step-description'>
              <p>
                <strong>Development</strong>
              </p>
              <p>Once you provide us with the content you'd like to include on your website, such as your love story, event details, and RSVP information, we'll build your website from the ground up within 1-2 weeks.</p>
            </div>
            <p className='step-label'>STEP TWO</p>
          </div>
          <div className='step step-three'>
            <img src={inviteImage} alt='Invitation Mockup' className='step-image' />
            <div className='step-description'>
              <p>
                <strong>Send Invites</strong>
              </p>
              <p>Once your website is ready, you'll have the opportunity to review it and request any final adjustments. When you're satisfied, we'll launch your stunning wedding website for all your guests to see.</p>
            </div>
            <p className='step-label'>STEP THREE</p>
          </div>
        </div>
      </section>
      <section id='testimonials'>
        <h1>What people say about us</h1>
        <div className='testimonial-container'>
          <div className='testimonial'>
            <img src={testimonialPhotoOne} alt='Antoinette & Hanro' className='testimonial-image' />
            <h6>Antoinette & Hanro</h6>
            <p>"We couldn't be happier with the incredible work Wedify did on our wedding website! From the initial consultation to the final touches, they demonstrated exceptional creativity, attention to detail, and a genuine passion for their craft. They perfectly captured our vision, creating a stunning, user-friendly website that beautifully reflects our love story. Their professionalism and responsiveness made the entire process smooth and stress-free. We received numerous compliments from our guests on the design and functionality of our site. If you're engaged and looking for a talented and reliable website designer, we wholeheartedly recommend Wedify. Their work exceeded our expectations and made our wedding planning experience even more special."</p>
          </div>
          <div className='testimonial'>
            <img src={testimonialPhotoTwo} alt='Nina & Seth' className='testimonial-image' />
            <h6>Nina & Seth</h6>
            <p>"Working with Wedify was a fantastic experience! The personalised wedding website they created for us was not only beautiful but also incredibly functional. The team was very responsive and made sure every detail was perfect!. Our guests loved being able to find all the information they needed in one place, and we loved how stress-free it made our planning. We highly recommend Wedify to any couple looking for a unique and memorable wedding website."</p>
          </div>
          <div className='testimonial'>
            <img src={testimonialPhotoThree} alt='Christine & Megan' className='testimonial-image' />
            <h6>Christine & Megan</h6>
            <p>"We are beyond thrilled with our wedding website from Wedify! They went above and beyond to ensure our site was exactly what we envisioned. The level of customisation was incredible, allowing us to include personal touches that truly reflected our love story. The RSVP system was a lifesaver, and the ability to share our photo gallery and event details with guests was fantastic. We received so many compliments on the website's design and functionality. Thank you for making our special day even more memorable!"</p>
          </div>
        </div>
      </section>
      <section id='socials'>
        <h1>Let's bring your wedding website to life!</h1>
        <div className='socialMedia-container'>
          <a href='https://www.instagram.com/wedify.co.za?igsh=ejZvcDZ5bzdtd3Fi&utm_source=qr' target='_blank' rel='noreferrer'>
            <FaInstagram size={30} className='socialMedia-icon' />
          </a>
          <a href='https://www.tiktok.com/@wedify.co.za?_t=8njWTzf3OMv&_r=1' target='_blank' rel='noreferrer'>
            <FaTiktok size={30} className='socialMedia-icon' />
          </a>
          <a href='https://www.facebook.com/profile.php?id=61561830422630&mibextid=LQQJ4d' target='_blank' rel='noreferrer'>
            <FaFacebook size={54} className='socialMedia-icon facebook-icon' />
          </a>
          <a href='https://wa.me/message/GNF3L6JUHF5FF1' target='_blank' rel='noreferrer'>
            <FaWhatsapp size={30} className='socialMedia-icon' />
          </a>
          <a href='https://www.saweddings.co.za/event-planning-17/stationery-invitations-26/wedify-co-za' target='_blank' rel='noreferrer'>
            <img src={SAweddingsBadge} alt='' className='socialMedia-icon badge' />
          </a>
        </div>
        <a href='mailto:info@wedify.co.za'>
          <strong>info@wedify.co.za</strong>
        </a>
        <a href='#landing' className='pricingLink'>Back To Top</a>
      </section>
    </div>
  );
}
