import React, { useState } from 'react';
import { IoCheckmark } from "react-icons/io5";
import TermsPDF from "./Wedify - Terms & Conditions.pdf";
import PrivacyPDF from "./Wedify - Privacy Policy.pdf";
import payfastLogo from '../../images/payfastLogo.png';
import { Link } from 'react-router-dom';
import './Pricing.css';

export default function Pricing() {

  // State for agreement checkbox
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);

  // Variables for Order Details
  const websiteDeposit = 1500 / 2;
  const customDomainPrice = 113.85;
  const totalPrice = (websiteDeposit + customDomainPrice).toFixed(2);

  // Function for changing checkbox state
  const handleCheckboxChange = (event) => {
    setIsAgreementChecked(event.target.checked);
  };

  return (
    <div className='Pricing'>
      <div className='pricing-breakdown'>
        <h6>Pricing</h6>
        <p className='price'>
          <span className='currency'>R</span>
          1,500</p>
        <p id='onceOff'>ONCE - OFF</p>
        <div className='pricing-breakdown-container'>
          <p className='pricing-breakdown-item'>
            <IoCheckmark size={20} className='pricing-breakdown-icon' /> Single or Multi-Page Website
          </p>
          <p className='pricing-breakdown-item'>
            <IoCheckmark size={20} className='pricing-breakdown-icon' /> Fully-Customisable
          </p>
          <p className='pricing-breakdown-item'>
            <IoCheckmark size={20} className='pricing-breakdown-icon' /> Event Details and Map
          </p>
          <p className='pricing-breakdown-item'>
            <IoCheckmark size={20} className='pricing-breakdown-icon' /> RSVP Management
          </p>
          <p className='pricing-breakdown-item'>
            <IoCheckmark size={20} className='pricing-breakdown-icon' /> Custom Domain Name*
          </p>
          <p className='pricing-breakdown-item'>
            <IoCheckmark size={20} className='pricing-breakdown-icon' /> Post-Wedding Updates
          </p>
        </div>
        <p className='pricing-disclaimer'>*Please note that a custom domain registration fee of +-R100 will apply and is charged separately. </p>
      </div>
      <div className='pricing-content'>
        <div className='moveInTop'>
          <h1>Our Fixed-Rate Promise</h1>
          <p>You heard that right! At Wedify, we believe in simplicity and transparency when it comes to creating your perfect wedding website. That's why we offer a straightforward, flat-rate pricing for every project. Whether you're planning an intimate gathering or a grand celebration, our fixed-rate ensures you receive the same exceptional service and attention to detail.</p>
          <h6>Here's what you get:</h6>
          <ul>
            <li>
              <strong>Single or Multi-page Website:</strong> Whether you need a simple one-page site or a detailed multi-page experience, we've got you covered with our tech-savvy &nbsp;
              <Link to="/features">
                <u>features.</u>
              </Link>
            </li>
            <li>
              <strong>Fully Customisable:</strong> Your wedding is unique, and your website should be too. That's why we offer fully custom-built sites to reflect your style and story.
            </li>
            <li>
              <strong>Custom Domain Name:</strong> Stand out with a personalized domain name that's as unique as your celebration.*
            </li>
            <li>
              <strong>Digital 'Save the Date' and Invite:</strong> Our digital save-the-dates and invites are designed to match your website, ensuring your guests mark their calendars and eagerly await your celebration using the included QR code.
            </li>
            <li>
              <strong>Custom Monogram:</strong> Should you not yet have one, we will design a custom monogram to use on your website, save-the-date and invites!
            </li>
            <li>
              <strong>Post-Wedding Updates:</strong> Keep the memories alive with post-wedding updates such as a thank you note to your guests and photo sharing.
            </li>
          </ul>
          <p id='costsDisclaimer'>*Please note that domain registration costs are billed directly by third-party providers and are unfortunately outside of our control, though we try our best to ensure you get the best possible service at the most competitive rates.</p>
          <h6>Our Commitment to You</h6>
          <p>With Wedify, you're not just getting a website; you're getting a dedicated team committed to making your wedding planning process as smooth as possible. We believe in transparency, simplicity, and exceptional service, so you can focus on what truly matters—celebrating your love.</p>
          <h6>Easy and Secure Payment</h6>
          <p>We offer a straightforward payment process with a 50% deposit to get started. The remaining balance is due upon completion, ensuring you're completely satisfied with your website before finalising the payment. All transactions are securely processed through PayFast, providing you with peace of mind.</p>
          <p>To ensure the payment is correctly matched to your order and to keep you informed throughout the process, please make sure to
            <strong> enter your email address </strong>
            at checkout. This will allow us to verify your payment and provide timely updates and support.</p>
        </div>
        <div className='pricing-summary'>
          <h6>Checkout</h6>
          <hr />
          <div className='summary-item'>
            <div>
              <p className='item-name'>Wedding Website <span className='item-info'>(50% Deposit)</span></p>
              <p className='item-description'>To start the process, we kindly ask for a 50% deposit.
                <br />
                The rest of the payment will be due upon completion.</p>
            </div>
            <div>
              <p className='item-price'>R {websiteDeposit}</p>
            </div>
          </div>
          <div className='summary-item'>
            <div>
              <p className='item-name'>Domain Name Registration <span className='item-info'>(12 Months)</span></p>
              <p className='item-description'>Domain name registration fees are set by the
                <br />
                domain registry and are required for all websites.</p>
            </div>
            <div>
              <p className='item-price'>R {customDomainPrice}</p>
            </div>
          </div>
          <div className='pricing-agreement'>
            <input type='checkbox' id='agreement' name='agreement' required onChange={handleCheckboxChange} />
            <label htmlFor='agreement'>
              I agree to the &nbsp;
              <a href={TermsPDF} target='_blank' rel='noreferrer' className='agreement-link'>Terms and Conditions</a>
              &nbsp; and &nbsp;
              <a href={PrivacyPDF} target='_blank' rel='noreferrer' className='agreement-link'>Privacy Policy</a>
            </label>
          </div>
          <a href='https://payfast.io/?gclid=CjwKCAjwp4m0BhBAEiwAsdc4aCwL5nENlIwKEBRqfz7TFjS-RwkALovw6aKzmHZcHHvUp_9duL1ysxoCyQ4QAvD_BwE' rel='noopener noreferrer' target='_blank'>
            <img src={payfastLogo} alt="Payfast by Network" className='payfastLogo' />
          </a>
          <div className='summary-total'>
            <p id='total'>
              <strong>TOTAL:</strong>
              <p className='total-amount'>R {totalPrice}</p>
              {isAgreementChecked &&
                <a href='https://payf.st/y02a8' className='payNowButton'>PAY NOW</a>
              }
              {!isAgreementChecked &&
                <p className='payNowButton-disabled'>PAY NOW</p>
              }
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}